import { ColumnDef, Row } from "@tanstack/react-table";
import Button from "components/ui/Button";
import { useMemo } from "react";
import Order from "models/Order";
import { StatusBadge } from "components/ui/Badge";
import consts from "utils/consts";
import { useMutation } from "@tanstack/react-query";
import api from "@/api";

interface CancelButtonProps {
  order: Order;
  onCancelSuccess: () => void,
  onCancelError: () => void
}

const CancelButton = ({ order, onCancelSuccess, onCancelError }: CancelButtonProps) => {
  const { mutate, isLoading } = useMutation(
    async (orderId: string) => api.delete(`/orders/${orderId}`), 
    {
      onSuccess: onCancelSuccess,
      onError: onCancelError
    }
  )

  return (
    <Button
      loading={isLoading}
      onClick={(e) => {
        e.stopPropagation();
        mutate(order.id)
      }}
      variant="secondary"
      className="max-h-[35px] max-w-[100px]"
    >
      Cancel
    </Button>
  )
} 

const usePortfolioTableColumns = ({ onCancelSuccess, onCancelError }: { onCancelSuccess: () => void; onCancelError: () => void }) => {
  const columns = useMemo(() => {
    const cols: ColumnDef<Order>[] = [
      {
        id: "side",
        header: "Type",
        accessorKey: "type",
        size: 250,
        cell: ({ row: { original: order } }: { row: Row<Order> }) => <StatusBadge color={order.type === "buy" ? "green" : "red" } text={order.type} />
      },
      {
        id: "name",
        header: "Name",
        size: 250,
        cell: ({ row: { original: order } }: { row: Row<Order> }) => order.type === 'buy' ? order.buyToken.name : order.sellToken.name,
      },
      {
        id: "amount",
        header: "Amount",
        size: 250,
        cell: ({ row: { original: order } }: { row: Row<Order> }) => order.type === 'buy' ? order.buy_amount : order.sell_amount,
      },
      {
        id: "price",
        header: "Price",
        size: 250,
        cell: ({ row: { original: order } }: { row: Row<Order> }) => `${order.type === 'buy' ? order.sell_amount : order.buy_amount} ${consts.defaultCurrencySymbol}`,
      },
      {
        id: "filledAmount",
        header: "Filled Amount",
        size: 250,
        cell: ({ row: { original: order } }: { row: Row<Order> }) => {
          const value = order.type === 'buy' 
            ? ((order.buy_amount - order.buy_remaining_amount) / order.buy_amount * 100).toFixed(2) + " / 100%"
            : ((order.sell_amount - order.sell_remaining_amount) / order.sell_amount * 100).toFixed(2) + " / 100%";

          return <StatusBadge color="yellow" text={value} />
        }
      },
      {
        id: "action",
        header: "",
        cell: ({ row: { original: order } }: { row: Row<Order> }) => (
          <div className="flex h-8 items-center justify-end gap-2">
            <CancelButton order={order} onCancelSuccess={onCancelSuccess} onCancelError={onCancelError} />
          </div>
        ),
      },
    ];

    return cols;
  }, []);

  return columns;
};

export default usePortfolioTableColumns;
